<template>
    <div class="home">
        <div class="home-top">
            <img class="home-top-bg" src="../assets/img_phone_d.png" alt="">

            <div class="home-top-content">

                <div class="logo-box">
                    <img src="../assets/icon_logo.png" alt="magic lamp">
                </div>
                <div class="text1">
                    Silent AI
                </div>
            </div>
        </div>

        <div class="home-bottom">
            <div class="text2">
                Silent AI can help you <br>
                fall asleep faster
            </div>

            <div class="qrcode-inline">
                <div class="qrcode-box">
                    <div id="qrcode1" ref="qrcode1" style="display: none"></div>
                    <div class="code-h">
                        <img :src="qrImsSrc" alt="">
                    </div>
                    <img class="store-block" src="../assets/icon_p_as.png" alt="">
                </div>
                <div class="qrcode-box">
                    <div id="qrcode2" ref="qrcode2" style="display: none"></div>
                    <div class="code-h">
                        <img :src="qrImsSrc2" alt="">
                    </div>
                    <img class="store-block" src="../assets/icon_p_gp.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'

    import QRCode from "qrcodejs2";

    export default {
        name: 'Home',
        data() {
            return {
                //苹果
                // link1: "http://dl.smart.silentai.cn/appstore",
                link1: "http://dl.smart.silentai.cn/lamp/appstore",
                //google
                // link2: "http://dl.smart.silentai.cn/googleplay",
                link2: "http://dl.smart.silentai.cn/lamp/googleplay",
                code1: null,
                code2: null,
                qrImsSrc: "",
                qrImsSrc2: "",
            };
        },
        mounted() {
            this.showQRcode();
        },
        methods: {
            qrcode1(qWidth, qHeight, qText, qRender) {
                this.code1 = new QRCode("qrcode1", {
                    width: qWidth,
                    height: qHeight,
                    text: qText,
                    render: qRender
                });
                var cv1 = document.getElementById('qrcode1');
                var myCanvas = cv1.getElementsByTagName('canvas')[0];
                var imgSrc = myCanvas.toDataURL("image/png");
                this.qrImsSrc = imgSrc;
            }
            ,
            qrcode2(qWidth, qHeight, qText, qRender) {
                this.code2 = new QRCode("qrcode2", {
                    width: qWidth,
                    height: qHeight,
                    text: qText,
                    render: qRender
                });

                var cv2 = document.getElementById('qrcode2');
                var myCanvas = cv2.getElementsByTagName('canvas')[0];
                var imgSrc = myCanvas.toDataURL("image/png");
                this.qrImsSrc2 = imgSrc;
            }
            ,
            showQRcode() {
                //二维码初始化 点击一次添加一个二维码
                this.$refs.qrcode1.innerHTML = "";
                this.$refs.qrcode2.innerHTML = "";
                this.$nextTick(function () {
                    this.qrcode1(100, 100, this.link1, "img");
                    this.qrcode2(100, 100, this.link2, "img");
                });
            }
        }
    }
</script>

<style scoped>
    .home {

    }

    .home-top {
        position: relative;
        width: 100%;
    }

    .home-top-bg {
        width: 100%;
    }

    .home-top-content {
        position: absolute;
        top: 10%;
        left: 0;
        right: 0;
    }

    .logo-box {
        margin: 0 auto 0 auto;
        width: 110px;
        height: 110px;
    }

    .logo-box img {
        width: 100%;
        height: 100%;
    }

    .text1 {
        margin-top: 10px;
        text-align: center;
        font-size: 24px;
        color: #ffffff;
    }

    .home-bottom {
        padding-top: 10px;
    }

    .text2 {
        width: 70%;
        margin: 0 auto;
        text-align: center;
        color: #fff;
        font-size: 18px;
    }


    .qrcode-inline {
        margin-top: 24px;
    }

    .qrcode-box {
        display: inline-block;
        vertical-align: top;
        margin: 0 12px;
        text-align: center;
        padding: 15px 20px 0 20px;
        /*height: 240px;*/

        background: #ffffff;
    }

    .store-block {
        margin: 12px 0 12px 0;
        width: 100px;
    }

    #qrcode1 img {
        user-select: none;
    }

    .code-h {
        width: 100px;
        height: 100px;
    }

    .code-h img {
        width: 100%;
        height: 100%;
    }
</style>

